<template>
  <WildlandtrekkingRegistrationPage
    :headline="headline"
    :introduction="introduction"
    :page="page"
  />
</template>

<script lang="ts">
import Vue from "vue";
import { InitPageParams } from "lib/utils/initPage";
import {
  setNewRelicSpanAttributes,
  setNewRelicTransactionName,
} from "~~/lib/utils/logger/instrument";
import { getBaseLink } from "~~/lib/utils/link/getBaseLink";
import WildlandtrekkingRegistrationPage from "~/components/WildlandtrekkingRegistration/WildlandtrekkingRegistrationPage.vue";
import { WildlandtrekkingRegistrationPage as WildlandtrekkingRegistrationPageResponse } from "~~/lib/types/Contentstack/ContentTypes/WildlandtrekkingRegistrationPage";
import { initPage } from "~~/lib/utils/initPage";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Component, logTags.Page.Wildlandtrekking],
});

export default Vue.extend({
  name: "WildlandtrekkingRegistration",
  components: {
    WildlandtrekkingRegistrationPage,
  },
  async asyncData({
    store,
    i18n,
    route,
    params,
    $config,
  }): Promise<WildlandtrekkingRegistrationPageResponse | undefined> {
    try {
      const requestParams: InitPageParams = {
        locale_iso: i18n.localeProperties.iso,
        locale_code: i18n.locale,
        locale_domain: $config.public.baseUrl,
        path: route.path,
        slug: getBaseLink(route.path),
        currency_code:
          params.currencyCode || i18n.localeProperties.currencyCode,
        current_path: getBaseLink(route.path),
        page_type: "content",
      };

      setNewRelicTransactionName("WildlandtrekkingRegistration");
      setNewRelicSpanAttributes({
        locale: i18n.locale,
        currencyCode: params.currencyCode || i18n.localeProperties.currencyCode,
        path: route.path,
      });

      const pageContent: WildlandtrekkingRegistrationPageResponse =
        await $fetch("/api/nuxt/wildlandtrekking-registration", {
          params: requestParams,
        });
      const [
        algoliaPublicSearchKeyDispatcher,
        salesRegionDispatcher,
        notificationAndMenuItemsDispatcher,
      ] = initPage(store, requestParams);

      await Promise.all([
        algoliaPublicSearchKeyDispatcher,
        salesRegionDispatcher,
        notificationAndMenuItemsDispatcher,
      ]);

      return pageContent;
    } catch (error) {
      logger.error(
        "Error occurred in asyncData in wildlandtrekking-registration.vue",
        error
      );
    }
  },
  data() {
    return {
      headline: {},
      introduction: {},
      page: {},
    };
  },
});
</script>
